import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Grid, Button, Snackbar,  DialogTitle,
  DialogContent,Dialog,
  DialogActions, } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios
import { domain } from "../Components/config";
import { styled } from "@mui/material/styles";

const tabData = [
  { label: "All", image: "/assets/all-da76a7fc.png" },
  { label: "Loteria", image: "/assets/gamecategory_20240611172507k9pn.png" },
  { label: "Original", image: "/assets/gamecategory_20240611172824l19p.png" },
  { label: "Slots", image: "/assets/gamecategory_20240611172950bsx2.png" },
  { label: "Sports", image: "/assets/gamecategory_20240611172928bnqo.png" },
  { label: "Casino", image: "/assets/gamecategory_20240611172909nn2o.png" },
  { label: "PVC", image: "/assets/gamecategory_20240611172848skb1.png" },
  { label: "Fishing", image: "/assets/gamecategory_20240611172453o1n2.png" },
];

const Loteria = [
  {
    id: 1,
    imgSrc: "/assets/20240830_115012.png",
    game: "Win Go",
    path: "/timer/30sec",
  },
  {
    id: 2,
    imgSrc: "/assets/20240830_115031.png",
    game: "k3",
    path: "/k3/1min",
  },
  {
    id: 3,
    imgSrc: "/assets/20240830_114902.png",
    game: "5d",
    path: "/5d/1min",
  },
];

const Characteristic = [
  {
    id: 1,
    imgSrc: "/assets/800_20240807164906191.png",
    game: "Aviator",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/mines.png", game: "boom", odds: 87.78 },
  { id: 3, imgSrc: "/assets/110.png", game: "limbo", odds: 89.47 },
  { id: 4, imgSrc: "/assets/107.png", game: "hotline", odds: 88.76 },
  { id: 5, imgSrc: "/assets/101.png", game: "hilo", odds: 89.77 },
  { id: 6, imgSrc: "/assets/plinko.png", game: "plinko", odds: 88.37 },
  { id: 7, imgSrc: "/assets/dice.png", game: "dice", odds: 84.77 },
  { id: 8, imgSrc: "/assets/keno.png", game: "keno", odds: 88.74 },
  { id: 9, imgSrc: "/assets/goal.png", game: "goal", odds: 89.71 },
];

const Hotslot = [
  {
    id: 1,
    imgSrc: "/assets/800_20240601192244182.jpg",
    game: "Win Go",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/22001.png", game: "Win Go", odds: 81.83 },
  { id: 3, imgSrc: "/assets/98.png", game: "Win Go", odds: 88.84 },
  { id: 4, imgSrc: "/assets/royal.png", game: "Win Go", odds: 84.17 },
  { id: 5, imgSrc: "/assets/Ganesh.png", game: "K3", odds: 80.04 },
  { id: 6, imgSrc: "/assets/rabbit.png", game: "K3", odds: 91.46 },
];

const Original = [
  { id: 1, imgSrc: "/assets/800_20240807164906191.png", game: "Aviator" },
  { id: 2, imgSrc: "/assets/100.png", game: "Boom" },
  { id: 3, imgSrc: "/assets/902.png", game: "Win Go" },
  { id: 4, imgSrc: "/assets/803.png", game: "Aviator 5min" },
  { id: 5, imgSrc: "/assets/802.png", game: "Aviator 3min" },
  { id: 6, imgSrc: "/assets/114.png", game: "Horse racing" },
  { id: 7, imgSrc: "/assets/110.png", game: "limbo" },
  { id: 8, imgSrc: "/assets/107.png", game: "hotline" },
  { id: 9, imgSrc: "/assets/101.png", game: "hilo" },
  { id: 10, imgSrc: "/assets/197.png", game: "color game" },
  { id: 11, imgSrc: "/assets/200.png", game: "pappu playing" },
  { id: 12, imgSrc: "/assets/224.png", game: "go rush" },
  { id: 13, imgSrc: "/assets/229.png", game: "mines" },
  { id: 14, imgSrc: "/assets/232.png", game: "tower" },
  { id: 15, imgSrc: "/assets/233.png", game: "hilo" },
  { id: 16, imgSrc: "/assets/235.png", game: "limbo" },
  { id: 17, imgSrc: "/assets/236.png", game: "wheel" },
];

const Slots = [
  {
    id: 1,
    imgSrc: "/assets/vendorlogo_202406081741318xaq.png",
    game: "jili",
  },
  { id: 2, imgSrc: "/assets/vendorlogo_2024060817401729ls.png", game: "pg" },
  { id: 3, imgSrc: "/assets/vendorlogo_20240608174232lg9w.png", game: "jdb" },
  { id: 4, imgSrc: "/assets/vendorlogo_20240608174248pilj.png", game: "cq" },
  { id: 5, imgSrc: "/assets/vendorlogo_20240608174309h9ns.png", game: "n" },
  {
    id: 6,
    imgSrc: "/assets/vendorlogo_20240608174102vuo1.png",
    game: "evolution",
  },
  { id: 7, imgSrc: "/assets/vendorlogo_202406081748253wsh.png", game: "mn" },
];

const Sports = [
  { id: 1, imgSrc: "/assets/gsports.png" },
  { id: 2, imgSrc: "/assets/cmd.png" },
  { id: 3, imgSrc: "/assets/saba.png" },
];

const Casino = [
  { id: 1, imgSrc: "/assets/roulette1.png", game: "roulette1" },
  { id: 2, imgSrc: "/assets/dragontigar.png", game: "dragontigar" },
  { id: 3, imgSrc: "/assets/roulette3.png", game: "roulette3" },
  { id: 4, imgSrc: "/assets/baccarat.png", game: "baccarat" },
  { id: 5, imgSrc: "/assets/roulette4.png", game: "roulette4" },
  { id: 6, imgSrc: "/assets/sicbo2.png", game: "sicbo2" },
  { id: 7, imgSrc: "/assets/sicbo.png", game: "sicbo" },
  { id: 8, imgSrc: "/assets/redblueDuel.png", game: "redblueDuel" },
  { id: 9, imgSrc: "/assets/playboy.png", game: "playboy" },
];

const Pvc = [
  { id: 1, imgSrc: "/assets/365games.png" },
  { id: 2, imgSrc: "/assets/v8poker.png" },
];

const Fishing = [
  { id: 1, imgSrc: "/assets/AB3 (1).png" },
  { id: 2, imgSrc: "/assets/AT01.png" },
  { id: 3, imgSrc: "/assets/AT05.png" },
  { id: 4, imgSrc: "/assets/GO02.png" },
  { id: 5, imgSrc: "/assets/67001.png" },
  { id: 6, imgSrc: "/assets/7001.png" },
  { id: 7, imgSrc: "/assets/7002.png" },
  { id: 8, imgSrc: "/assets/7003.png" },
  { id: 9, imgSrc: "/assets/7004.png" },
  { id: 10, imgSrc: "/assets/7005.png" },
  { id: 11, imgSrc: "/assets/7006.png" },
  { id: 12, imgSrc: "/assets/7007.png" },
  { id: 13, imgSrc: "/assets/1.png" },
  { id: 14, imgSrc: "/assets/119.png" },
  { id: 15, imgSrc: "/assets/20.png" },
  { id: 16, imgSrc: "/assets/212.png" },
  { id: 17, imgSrc: "/assets/32.png" },
  { id: 18, imgSrc: "/assets/42.png" },
  { id: 19, imgSrc: "/assets/60.png" },
  { id: 20, imgSrc: "/assets/71.png" },
  { id: 21, imgSrc: "/assets/74.png" },
  { id: 22, imgSrc: "/assets/82.png" },
  { id: 23, imgSrc: "/assets/510.png" },
  { id: 24, imgSrc: "/assets/SFG_WDFuWaFishing.png" },
  { id: 25, imgSrc: "/assets/SFG_WDGoldBlastFishing.png" },
  { id: 26, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 27, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 28, imgSrc: "/assets/SFG_WDGoldenTyrantFishing.png" },
  { id: 29, imgSrc: "/assets/SFG_WDMerryIslandFishing.png" },
];

const categoriesMap = {
  Characteristic: Characteristic,
  Hotslot: Hotslot,
  Original: Original,
  Slots: Slots,
  Sports: Sports,
  Casino: Casino,
  Pvc: Pvc,
  Fishing: Fishing,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "70%",
    maxWidth: "330px",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: "linear-gradient(90deg, #4782ff 0%, #59adff 100%)",
  color: "white",
  padding: theme.spacing(1.5),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2.5),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  backgroundColor: "#f5f5f5",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  padding: theme.spacing(1, 2),
  textTransform: "none",
  // fontWeight: "bold",
}));


const RechargeDialog = ({ open, onClose, onConfirm, selectedGame }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Typography variant="h6" component="div" fontWeight="bold">
          Recharge Required
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography sx={{ marginTop: "0.5rem" }} variant="body1" gutterBottom>
          To enter{" "}
          <Box component="span" fontWeight="bold">
            {selectedGame?.game}
          </Box>
          , you need to make a deposit first.
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={2}>
          Recharging your account will allow you to enjoy all the exciting
          features of our games!
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={onClose} color="inherit">
          Cancel
        </StyledButton>
        <StyledButton
          onClick={onConfirm}
          variant="contained"
          style={{
            background: "linear-gradient(90deg, #4782ff 0%, #59adff 100%)",
            color: "white",
          }}
        >
          Recharge Now
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};


const Games = () => {
  const numberOfOriginalItems = Original.length;
  const numberOfHotSlotItems = Hotslot.length;
  const numberOfcharacteristicItems = Characteristic.length;
  const numberOfSlotsItems = Slots.length;
  const numberOfSportsItems = Sports.length;
  const numberOfCasinoItems = Casino.length;
  const numberOfpvcItems = Pvc.length;
  const numberOfFishingItems = Fishing.length;
  const numberOfLoteriaItems = Loteria.length;
  const [tabValue, setTabValue] = useState(0);
  const [phoneUserUid, setPhoneUserUid] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [firstDepositMade, setFirstDepositMade] = useState(true);
  const [needToDepositFirst, setNeedToDepositFirst] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleImageClick = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 1000);
  };

  // Fetch user info when the component mounts using Axios
  useEffect(() => {
    // Fetch user info when the component mounts
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${domain}/user`);
        const data = response.data;

        console.log("Fetched User Data:", data);

        if (data) {
          setPhoneUserUid(data.user.mobile); // Update the state with phoneUserUid
        } else {
          console.error("Failed to fetch user info:", data.message);
        }
      } catch (error) {
        console.error("Error fetching the API:", error);
      }
    };

    fetchUserInfo(); // Call the function when the component mounts
  }, []); // Add `domain` as a dependency if it's dynamic, otherwise leave it empty if static

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`${domain}/user`);
        const userData = userResponse.data;

        if (userData && userData.user) {
          setFirstDepositMade(userData.user.firstDepositMade);
        }

        if (userData) {
          setPhoneUserUid(userData.user.mobile);
        }

        const depositResponse = await axios.get(
          `${domain}/need-to-deposit-first`
        );
        const depositData = depositResponse.data;

        if (depositData && depositData.success) {
          setNeedToDepositFirst(depositData.data.needToDepositFirst);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleGameClick = (game) => {
    if (!firstDepositMade && needToDepositFirst) {
      setSelectedGame(game);
      setOpenDialog(true);
    } else {
      navigate(game.path);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmRecharge = () => {
    // Navigate to recharge page or trigger recharge process
    navigate("/recharge"); // Adjust this path as needed
  };


  // Function to handle click on the Aviator game
  const handleAviatorClick = () => {
    if (phoneUserUid) {
      // const url = `https://jdbmain.eazywin.xyz/jdb/post?iv=05bae19880a21dd1&key=3467d9770b6bdd41&uid=${phoneUserUid}&serverUrl=https://jdb.Z1 Clubgames.com&parent=intimate&gType=22&mType=22001`;
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22001`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleBoomClick = () => {
    if (phoneUserUid) {
      const url = ` https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22005`;

      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleLimboClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=9&mType=9018`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleHotlineClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22009`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleHiloClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22006`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleFortuneNekoClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22004`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const handleDiceClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22002`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };
  const handleGoalClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22003`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };
  const handleKenoClick = () => {
    if (phoneUserUid) {
      const url = `https://jdbmain.imitator-host.site/jdb/post?iv=f1ab7cea8106a3e4&key=b4d70df8d5c2857c&uid=${phoneUserUid}&serverUrl=https://jdb.profit-india.com&parent=imitator&gType=22&mType=22007`;
      window.location.href = url;
    } else {
      console.error("User UID not available yet.");
    }
  };

  const getClickHandler = (gameId) => {
    switch (gameId) {
      // case 1:
      //   return handleAviatorClick;
      // // case 2:
      // //   return handleBoomClick;
      // case 3:
      //   return handleLimboClick;
      // case 4:
      //   return handleHotlineClick;
      // case 5:
      //   return handleHiloClick;
      // case 6:
      //   return handleFortuneNekoClick;
      // case 7:
      //   return handleDiceClick;
      // case 8:
      //   return handleKenoClick;
      // case 9:
      //   return handleGoalClick;
      default:
        return () => {};
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/head"); // Navigate to the specified link
  };
  const handleClick1 = () => {
    navigate("/k3"); // Navigate to the specified link
  };
  const handleClick2 = () => {
    navigate("/trx"); // Navigate to the specified link
  };

  const [currentIndexes, setCurrentIndexes] = useState({
    Loteria: 0,
    Characteristic: 0,
    Hotslot: 0,
    Original: 0,
    Slots: 0,
    Sports: 0,
    Casino: 0,
    Pvc: 0,
    Fishing: 0,
  });
  const handlePrev = (category) => {
    if (!categoriesMap[category]) return;
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] -
          1 +
          Math.ceil(categoriesMap[category]?.length / 3)) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }));
  };

  const handleNext = (category) => {
    if (!categoriesMap[category]) return; // Check if the category exists
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] + 1) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }));
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: "#f0f0f0", padding: "10px" }}>
        <Box
          sx={{
            display: "flex",
            overflowX: "auto",
            bgcolor: "white",
            borderRadius: "10px",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          {tabData.map((tab, index) => (
            <Box
              key={index}
              onClick={() => handleTabChange(null, index)}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: "40px",
                padding: "8px 8px",
                cursor: "pointer",
                backgroundColor: tabValue === index ? "#4781ff" : "white",
                color: tabValue === index ? "#ffffff" : "black",
                borderRadius: "8px",
                marginRight: "2px",
                "&:first-of-type": {
                  marginLeft: "8px",
                },
              }}
            >
              <img
                src={tab.image}
                alt={tab.label}
                style={{
                  width: "24px",
                  height: "24px",
                  marginBottom: "4px",
                }}
              />
              <Typography variant="caption" sx={{ fontSize: "10px" }}>
                {tab.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Loteria */}

      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <img
              src="/assets/loteria-0ccd41c5.png"
              alt="separator"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                marginRight: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Loteria
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderColor: "transparent",
                backgroundColor: "#f8f8f8",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                },
                display: "flex",
                alignItems: "center",
                px: 1.5,
                py: 0.5,
                textTransform: "none",
                borderRadius: "5px",
                height: "28px",
                minWidth: "unset",
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#adafc2",
                  whiteSpace: "nowrap",
                }}
              >
                ALL{" "}
                <span style={{ color: "#000000", marginLeft: "4px" }}>
                  {Loteria.length}
                </span>
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <IconButton
              onClick={() => handlePrev("Loteria")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
                marginRight: "4px",
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => handleNext("Loteria")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

       <>
        <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 0 }}>
          <Box sx={{ padding: "10px" }}>
            <Grid container spacing={1}>
              {Loteria.map((game) => (
                <Grid item xs={4} sm={4} md={4} key={game.id}>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "8px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={game.imgSrc}
                      alt={game.game}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleGameClick(game)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <RechargeDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmRecharge}
          selectedGame={selectedGame}
        />
      </>
      {/* characteristic */}
      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <img
              src="/assets/platformList-5db5d715.png"
              alt="separator"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                marginRight: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Characteristic
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderColor: "transparent",
                backgroundColor: "#f8f8f8",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                },
                display: "flex",
                alignItems: "center",
                px: 1.5,
                py: 0.5,
                textTransform: "none",
                borderRadius: "5px",
                height: "28px",
                minWidth: "unset",
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#adafc2",
                  whiteSpace: "nowrap",
                }}
              >
                ALL{" "}
                <span style={{ color: "#000000", marginLeft: "4px" }}>
                  {Characteristic.length}
                </span>
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <IconButton
              onClick={() => handlePrev("Characteristic")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
                marginRight: "4px",
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => handleNext("Characteristic")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 0 }}>
      <Snackbar
        open={showMessage}
        message="Coming Soon"
        style={{
          position: "fixed",
          color: 'black',
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        ContentProps={{
          style: {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
      />
      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1}>
          {Characteristic.map((game) => (
            <Grid item xs={4} sm={4} md={4} key={game.id}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: "8px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleImageClick}
              >
                <img
                  src={game.imgSrc}
                  alt={game.game}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                />
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "#f2f2f1",
                    borderRadius: "10px",
                    marginTop: "8px",
                    overflow: "hidden",
                    width: "120px",
                    height: "25px",
                  }}
                >
                  <Box
                    sx={{
                      width: `${game.odds}%`,
                      backgroundColor: "#4781ff",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "13px",
                        marginLeft: "5px",
                      }}
                    >
                      odds of {game.odds}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>

      {/* Hot Slots Section */}
      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <img
              src="/assets/clicksTopList-dfac71d9.png"
              alt="separator"
              style={{
                width: "22px",
                height: "22px",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                marginRight: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Hot Slots
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderColor: "transparent",
                backgroundColor: "#f8f8f8",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                },
                display: "flex",
                alignItems: "center",
                px: 1.5,
                py: 0.5,
                textTransform: "none",
                borderRadius: "5px",
                height: "28px",
                minWidth: "unset",
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#adafc2",
                  whiteSpace: "nowrap",
                }}
              >
                ALL{" "}
                <span style={{ color: "#000000", marginLeft: "4px" }}>
                  {Hotslot.length}
                </span>
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <IconButton
              onClick={() => handlePrev("Hotslot")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
                marginRight: "4px",
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => handleNext("Hotslot")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {Hotslot.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#f2f2f1",
                      borderRadius: "10px",
                      marginTop: "8px",
                      overflow: "hidden",
                      width: "120px",
                      height: "25px",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${game.odds}%`,
                        backgroundColor: "#4781ff",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        odds of {game.odds}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Original Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/flash-be6ad48f.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                Original
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#f8f8f8",
                  },
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 0.5,
                  textTransform: "none",
                  borderRadius: "5px",
                  height: "28px",
                  minWidth: "unset",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#adafc2",
                    whiteSpace: "nowrap",
                  }}
                >
                  ALL{" "}
                  <span style={{ color: "#000000", marginLeft: "4px" }}>
                    {Original.length}
                  </span>
                </Typography>
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => handlePrev("Original")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                  marginRight: "4px",
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => handleNext("Original")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ fontSize: "small", color: "white" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Typography sx={{ fontSize: "12px", textAlign: "left", mt: 1 }}>
          The Internal Game Is Independently Developed by the in999 Team, Which
          Is Fun, Fair, and Safe.
        </Typography>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Original}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Original: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Original.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Original.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Slot */}
      <Box sx={{ px: 2, mt: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <img
              src="/assets/slot-3fcac344.png"
              alt="separator"
              style={{
                width: "22px",
                height: "22px",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                marginRight: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Slots
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderColor: "transparent",
                backgroundColor: "#f8f8f8",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                },
                display: "flex",
                alignItems: "center",
                px: 1.5,
                py: 0.5,
                textTransform: "none",
                borderRadius: "5px",
                height: "28px",
                minWidth: "unset",
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#adafc2",
                  whiteSpace: "nowrap",
                }}
              >
                ALL{" "}
                <span style={{ color: "#000000", marginLeft: "4px" }}>
                  {Slots.length}
                </span>
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <IconButton
              onClick={() => handlePrev("Slots")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
                marginRight: "4px",
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => handleNext("Slots")}
              sx={{
                color: "black",
                backgroundColor: "#4781ff",
                "&:hover": {
                  backgroundColor: "#4781ff",
                },
                borderRadius: "4px",
                padding: "4px",
                minWidth: "28px",
                height: "28px",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "small", color: "white" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "12px" }}>
          <Grid container spacing={1}>
            {Slots.map((game) => (
              <Grid item xs={6} sm={6} md={6} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Sports Section */}
      <Box sx={{ mt: 0, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/sport-f0fdc902.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                Sports
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#f8f8f8",
                  },
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 0.5,
                  textTransform: "none",
                  borderRadius: "5px",
                  height: "28px",
                  minWidth: "unset",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#adafc2",
                    whiteSpace: "nowrap",
                  }}
                >
                  ALL{" "}
                  <span style={{ color: "#000000", marginLeft: "4px" }}>
                    {Sports.length}
                  </span>
                </Typography>
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => handlePrev("Sports")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                  marginRight: "4px",
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => handleNext("Sports")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ fontSize: "small", color: "white" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes}
            onChangeIndex={setCurrentIndexes}
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Sports.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Sports.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Casino Section */}

      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/video-0216ce19.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                Casino
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#f8f8f8",
                  },
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 0.5,
                  textTransform: "none",
                  borderRadius: "5px",
                  height: "28px",
                  minWidth: "unset",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#adafc2",
                    whiteSpace: "nowrap",
                  }}
                >
                  ALL{" "}
                  <span style={{ color: "#000000", marginLeft: "4px" }}>
                    {Casino.length}
                  </span>
                </Typography>
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => handlePrev("Casino")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                  marginRight: "4px",
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => handleNext("Casino")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ fontSize: "small", color: "white" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Casino}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Casino: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Casino.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Casino.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* PVC Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/chess-11735038.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                PVC
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#f8f8f8",
                  },
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 0.5,
                  textTransform: "none",
                  borderRadius: "5px",
                  height: "28px",
                  minWidth: "unset",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#adafc2",
                    whiteSpace: "nowrap",
                  }}
                >
                  ALL{" "}
                  <span style={{ color: "#000000", marginLeft: "4px" }}>
                    {Pvc.length}
                  </span>
                </Typography>
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => handlePrev("Pvc")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                  marginRight: "4px",
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => handleNext("Pvc")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ fontSize: "small", color: "white" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Pvc}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Pvc: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Pvc.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Pvc.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Fishing Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/fish-57b49990.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                Fishing
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "transparent",
                    backgroundColor: "#f8f8f8",
                  },
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                  py: 0.5,
                  textTransform: "none",
                  borderRadius: "5px",
                  height: "28px",
                  minWidth: "unset",
                  flexShrink: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#adafc2",
                    whiteSpace: "nowrap",
                  }}
                >
                  ALL{" "}
                  <span style={{ color: "#000000", marginLeft: "4px" }}>
                    {Fishing.length}
                  </span>
                </Typography>
              </Button>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <IconButton
                onClick={() => handlePrev("Fishing")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                  marginRight: "4px",
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small", color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => handleNext("Fishing")}
                sx={{
                  color: "black",
                  backgroundColor: "#4781ff",
                  "&:hover": {
                    backgroundColor: "#4781ff",
                  },
                  borderRadius: "4px",
                  padding: "4px",
                  minWidth: "28px",
                  height: "28px",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{ fontSize: "small", color: "white" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Fishing}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Fishing: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Fishing.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Fishing.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>
    </Box>
  );
};

export default Games;
