import React, { useEffect, useState } from "react";
import Mobile from "../Components/Mobile";
import IconButton from "@mui/material/IconButton";
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import axios from "axios";
import { domain } from "./config";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

const Coupenmainuser = ({ children }) => {
  const [couponCode, setCouponCode] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVh);
    setVh();

    return () => window.removeEventListener("resize", setVh);
  }, []);

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const response = await axios.get(`${domain}/envelop-transactions`, {
          withCredentials: true,
        });

        console.log(response.data);
        setHistoryData(response.data);
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        alert("Failed to fetch transaction history.");
      }
    };

    fetchTransactionHistory();
  }, []);

  const handleRedirect = () => {
    navigate("/activity");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${domain}/redeem-coupon`,
        { code: couponCode },
        { withCredentials: true }
      );
      alert(response.data);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else if (error.request) {
        alert("No response received from the server.");
      } else {
        alert("Error", error.message);
      }
    }
  };

  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box flexGrow={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#4781ff",
                padding: "8px 16px",
                color: "white",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <IconButton
                    sx={{ color: "white", ml: -5 }}
                    onClick={handleRedirect}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 8,
                    }}
                  >
                    Gift
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Box sx={{ backgroundColor: "#f2f2f1", pb: 5 }}>
              {" "}
              {/* Added bottom padding */}
              <img
                src="../assets/images/gift.png"
                alt="coupon"
                style={{ width: "100%", height: "200%" }}
              />
              <Box sx={{ backgroundColor: "#f2f2f1", padding: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: 2,
                    borderRadius: 3,
                  }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    align="left"
                    color="#adafc2"
                  >
                    Hi
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    align="left"
                    color="#adafc2"
                  >
                    We have a gift for you
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    align="left"
                    color="black"
                    sx={{ mt: 4, fontSize: "15px" }}
                  >
                    Please enter the gift Code below
                  </Typography>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <TextField
                      value={couponCode}
                      onChange={(event) => setCouponCode(event.target.value)}
                      placeholder="Please enter gift code"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: "10px",
                        borderRadius: "20px",
                        backgroundColor: "#f2f2f1",
                        "& .MuiInputBase-input::placeholder": {
                          color: "rgb(167,165,161)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: "20px",
                        background: "#4781ff",
                        color: "white",
                        "&:hover": {
                          background: "#3665cc",
                        },
                      }}
                    >
                      Receive
                    </Button>
                  </form>
                </Box>
              </Box>
              <Box sx={{ padding: 1, boxShadow: "none", mb: 5 }}>
                {" "}
                {/* Added bottom margin */}
                <Card
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    border: "none",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "black", textAlign: "left", mb: 2 }}
                    >
                      Gift History
                    </Typography>

                    {historyData.length > 0 ? (
                      <div
                        style={{
                          maxHeight: 300,
                          overflowY: "auto",
                          paddingRight: "10px", // Add some spacing to prevent content from touching the edge
                          msOverflowStyle: "none", // IE and Edge
                          scrollbarWidth: "none", // Firefox
                          WebkitScrollbar: "none", // Chrome, Safari, and Opera
                        }}
                      >
                        <style>{`
                          div::-webkit-scrollbar {
                            display: none;
                          }
                        `}</style>
                        <List>
                          {historyData.map((transaction, index) => (
                            <React.Fragment key={index}>
                              <ListItem
                                alignItems="flex-start"
                                sx={{
                                  background: "#f2f2f1",
                                  borderRadius: 2,
                                  mb: 1,
                                  padding: "10px 16px",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        color: "#4781ff",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Coupen: {transaction.code}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        color: "#4781ff",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ₹{transaction.amount}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "#4781ff" }}
                                    >
                                      {new Date(
                                        transaction.date
                                      ).toLocaleDateString()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              {index < historyData.length - 1 && (
                                <Divider
                                  variant="inset"
                                  component="li"
                                  sx={{ bgcolor: "rgba(0, 0, 0, 0.1)" }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </List>
                      </div>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        <img
                          src="../../assets/No data-amico.png"
                          alt="No data"
                          style={{ width: "100px", marginBottom: "10px" }}
                        />
                        <Typography variant="body2" sx={{ color: "white" }}>
                          No gift history available
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>

          {children}
        </Box>
      </Mobile>
    </div>
  );
};

export default Coupenmainuser;
